export const numberWithComma = (
	x: number | string,
	withDollar: boolean = false,
	round: boolean = false,
	fractionDigits: number = 2,
	options?: any,
): string => {
	const showK = withDollar && +x > 10 ** 5;
	return `${x < 0 ? '-' : ''}${withDollar ? '$' : ''}${((Math.abs(showK ? +x / 1000 : +x))
		.toLocaleString('en', {
			...options,
			minimumFractionDigits: round ? 0 : fractionDigits,
			maximumFractionDigits: round ? 0 : fractionDigits,
		}))}${showK ? 'K' : ''}`;
};
