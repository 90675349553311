import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Tag from 'antd/lib/tag';

import ServerPageProvider from '@common/react/components/Core/ServerPageProvider/ServerPageProvider';
import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';
import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { ItemEditorWrapper } from '@common/react/components/Core/ItemEditor/ItemEditorWrapper';
import ResizeObserverContainer from '@common/react/components/UI/ResizeObserverContainer/ResizeObserverContainer';

import { LogoLoaderPage } from '@commonTuna/react/components/UI/LogoLoader/LogoLoader';
import { getMoneyString } from '@commonTuna/react/components/Utils';
import ImageWithSkeleton from '@commonTuna/react/components/UI/ImageWithSkeleton/ImageWithSkeleton';

import AddToCartButton from '@app/components/UI/AddToCartButton/AddToCartButton';
import SpecialImageLoader from '@app/components/UI/SpecialImageLoader/SpecialImageLoader';
import ProductTag from '@app/components/Pages/Products/ProductTag';
import { Special } from '@app/objects/Special';
import SpecialsCarousel from '@app/components/UI/SpecialsCarousel/SpecialsCarousel';
import PreviewImageLoader from '@app/components/UI/SpecialImageLoader/PreviewImageLoader';

import '@app/scss/components/specialProductInfo.scss';
import WithPathLink from '@app/components/UI/WithPathLink/WithPathLink';
import useShoppingCart from '@app/hooks/useShoppingCart';
import { transformSpecialToCartItem } from '@app/objects/CartItem';
import Locations from '@app/components/Pages/DoctorPortal/Locations';
import { SimpleBreadCrumbs } from '@app/components/UI/Breadcrumbs/Breadcrumbs';

import noImage from '@images/no-image.jpg';

interface InnerProps {
	popularSpecials?: Array<Special>;
	forPreview?: boolean;
	showLocation?: boolean;
}

const BuyNow: React.FC<{ specialForStore, className? }> = (props) => {
	const history = useHistory();
	const { getItem, addCartItem } = useShoppingCart();
	const {
		specialForStore,
		className = '',
	} = props;
	const storedItem = getItem(specialForStore?.id);

	return <a
		onClick={(e) => {
			e.preventDefault();
			if (!storedItem) {
				addCartItem(specialForStore, 1);
			}
			history.push('/ordering');
		}}
		className={`special-buy-now-button ${className}`}
	>
		<span className="special-buy-now add-to-cart-btn">
			Buy now
		</span>
	</a>;
};

export const SpecialPageInner: React.FC<InnerProps> = ({ popularSpecials, forPreview = false, showLocation }) => {
	const [imgLoading, setImgLoading] = React.useState<boolean>(true);
	const [hide, setHide] = React.useState(true);
	const [height, setHeight] = React.useState(100);

	React.useEffect(() => {
		setHide(false);
	}, []);

	return <ItemEditorWrapper<Special>
		className="special-container"
		render={({ state: { item: special } }) => {
			const specialForStore = transformSpecialToCartItem(special);

			if (!forPreview) {
				return <>
					<div className="container">
						<div className="row">
							<SimpleBreadCrumbs
								links={[
									{
										title: 'Specials',
										path: '/specials',
									},
								]}
								title={special.name}
							/>
						</div>
					</div>
					<div className={`item-container special-container ${forPreview ? '' : 'container'}`}>
						<div className="item-info">
							<div className="left-side">
								<div className="item-img">
									{imgLoading
										? forPreview
											? <PreviewImageLoader />
											: <SpecialImageLoader />
										: null
									}
									<ImageWithSkeleton
										src={hide ? '' : special.originalAvatar ? `/remote/${special.originalAvatar}` : noImage}
										alt={special.name}
										onLoad={() => setImgLoading(false)}
										hidden={imgLoading}
									/>
								</div>
								<ResizeObserverContainer className="special-panel" onResize={({ height }) => setHeight(height + 20)}>
									<div className="special-price__container">
										<div className="item-img show-mobile show-mobile__block">
											{imgLoading
												? forPreview
													? <PreviewImageLoader />
													: <SpecialImageLoader />
												: null
											}
											<ImageWithSkeleton
												src={hide ? '' : special.originalAvatar ? `/remote/${special.originalAvatar}` : noImage}
												alt={special.name}
												onLoad={() => setImgLoading(false)}
												hidden={imgLoading}
											/>
										</div>
										{special.name && <h2 className="show-mobile show-mobile__block">{special.name}</h2>}
										<div className="price">
											<span className="special-price mr10">{getMoneyString(special.price)}</span>
											<span className="special-original-price">{getMoneyString(special.originalPrice)}</span>
										</div>
									</div>
									<div className="special-add-btn" key={special.id}>
										<AddToCartButton special={special} />
									</div>
									<div className="special-add-btn">
										<BuyNow className="text-center" specialForStore={specialForStore} />
									</div>
								</ResizeObserverContainer>
							</div>
							<div className="right-side">
								<div className="right-side__main">
									{special.name && <h1 className="clearfix">
										<div className="price ml10 pull-right show-mobile show-mobile__block">
											<span className="special-price mr10">{getMoneyString(special.price)}</span>
											<span className="special-original-price">{getMoneyString(special.originalPrice)}</span>
										</div>
										{special.name}
									</h1>}
									<hr className="show-mobile show-mobile__block" />
									<div className="right-side__info">
										{!showLocation && special.location
											&& <p className="map-point">
												<i className="fa fa-map-marker" />
												<WithPathLink prefix="clinic" path={special.location.portalPathEn}>
													{special.location.nameEn}
												</WithPathLink>
											</p>
										}
										{special?.procedures?.length > 0 && <>
											<h3>
												Procedures
											</h3>
											<div>
												{special.procedures.map((item) =>
													<Tag color="blue" key={item.id} style={{ fontSize: '16px' }}>
														{item.procedure.name}
													</Tag>)}
											</div>
										</>}
										{special?.products?.length > 0 && <>
											<h3>
												Products
											</h3>
											<div>
												{special.products.map((item) => item.product
													&& <div key={item.id}>
														<ProductTag product={item.product} />
													</div>)}
											</div>
										</>}
										{special.description && <p className="special-description custom-scroll ">{special.description}</p>}
									</div>
								</div>
								{showLocation && special.location && <Locations
									locations={[special.location]}
								/>}
							</div>
						</div>
					</div>
					{popularSpecials && popularSpecials.length > 0 && <div className="container">
						<SpecialsCarousel specials={popularSpecials.concat(popularSpecials)} withGradient />
					</div>}
					<style>
						{`@media (max-width: 767px) {.special-page ~ .site-footer {margin-bottom: ${height}px;}}`}
					</style>
				</>;
			}

			return <>
				<div className={`item-container special-container ${forPreview ? '' : 'container'}`}>
					<div className="item-info">
						<div className="left-side">
							<div className="item-img">
								{imgLoading
									? forPreview
										? <PreviewImageLoader />
										: <SpecialImageLoader />
									: null
								}
								<ImageWithSkeleton
									src={hide ? '' : special.originalAvatar ? `/remote/${special.originalAvatar}` : noImage}
									alt={special.name}
									onLoad={() => setImgLoading(false)}
									hidden={imgLoading}
								/>
							</div>
							{forPreview ? null
								: <>
									<div className="special-add-btn" key={special.id}>
										<AddToCartButton special={special} />
									</div>
									<div className="special-add-btn">
										<BuyNow className="text-center" specialForStore={specialForStore} />
									</div>
								</>
							}
						</div>
						<div className="right-side">
							<div className="right-side__main">
								{special.name && <h1>{special.name}</h1>}
								<div className="right-side__info">
									{special.location
										&& <p className="map-point">
											<i className="fa fa-map-marker" />
											<WithPathLink prefix="clinic" path={special.location.portalPathEn}>
												{special.location.nameEn}
											</WithPathLink>
										</p>
									}
									{special?.procedures?.length > 0 && <>
										<h3>
											Procedures
										</h3>
										<div>
											{special.procedures.map((item) =>
												<Tag color="blue" key={item.id} style={{ fontSize: '16px' }}>
													{item.procedure.name}
												</Tag>)}
										</div>
									</>}
									{special?.products?.length > 0 && <>
										<h3>
											Products
										</h3>
										<div>
											{special.products.map((item) => item.product
												&& <div key={item.id}>
													<ProductTag product={item.product} />
												</div>)}
										</div>
									</>}
									{special.description && <p className="special-description custom-scroll">{special.description}</p>}
									{forPreview ? null : <div className="special-price__container">
										<span className="special-price mr10">{getMoneyString(special.price)}</span>
										<span className="special-original-price">{getMoneyString(special.originalPrice)}</span>
									</div>}
								</div>
								{forPreview && <>
									<div className="special-price__container">
										<span className="special-price mr10">{getMoneyString(special.price)}</span>
										<span className="special-original-price">{getMoneyString(special.originalPrice)}</span>
									</div>
									<div className="special-add-btn" key={special.id}>
										<AddToCartButton special={special} />
									</div>
								</>}
							</div>
							{forPreview && <div className="right-side__details-btn">
								<BuyNow className="pull-left mr10" specialForStore={specialForStore} />
								<LinkWithPrevLocation to={`/special/${special.path}`} className="">Show more details</LinkWithPrevLocation>
							</div>}
						</div>
					</div>
				</div>
				{popularSpecials && popularSpecials.length > 0 && <div className="container">
					<SpecialsCarousel specials={popularSpecials} />
				</div>}
			</>;
		}}
		backPath=""
	/>;
};

const SpecialPageProvider: React.FC = () => {
	return (
		<ServerPageProvider
			path="special"
			defaultTitle="Special"
			loader={<LogoLoaderPage />}
			reloadByPathChange
			inner={(page) => (
				<div className="special-page">
					<ItemProvider
						id={0}
						type="special"
						loadRequest="getSpecials"
						item={page.item}
						skipInitLoad={!!page.item}
					>
						<SpecialPageInner popularSpecials={page.popularSpecials} showLocation />
					</ItemProvider>
				</div>
			)}
		/>
	);
};

export default SpecialPageProvider;
