import React from 'react';

import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/react';

import ResizeObserverContainer from '@common/react/components/UI/ResizeObserverContainer/ResizeObserverContainer';

import SpecialCard from '@app/components/Pages/Specials/SpecialCard';
import { Special } from '@app/objects/Special';
import { LocationPortal } from '@app/objects/CompanyPortal';

import '@app/scss/components/special.scss';
import '@app/scss/components/specialsCarousel.scss';

interface Props {
	specials: Array<Special>;
	location?: LocationPortal;
	count?: number;
	titleAsH2?: boolean;
	withGradient?: boolean;
}

const itemWidth = 300;

const SpecialsCarousel: React.FC<Props> = ({
	specials,
	location = undefined,
	count = 5,
	titleAsH2,
	withGradient,
}) => {
	const text = 'Popular Specials';
	const [spaceBetween, setSpaceBetween] = React.useState(30);

	return <ResizeObserverContainer
		onResize={({ width }) => {
			if (window.innerWidth > 640) {
				const count = Math.floor((width - 12) / itemWidth);
				setSpaceBetween(count > 1 && count <= specials.length ? (width - count * itemWidth) / (count - 1) : 30);
			} else {
				setSpaceBetween(30);
			}
		}}
		className="specials-carousel"
	>
		{withGradient ? <div className="gradient-header">
			<h2 className="cards-block__title text-center">{text}</h2>
		</div> : titleAsH2 ? <h2>{text}</h2> : <h1>{text}</h1>}
		<div className="special-list">
			<Swiper
				slidesPerView="auto"
				spaceBetween={spaceBetween}
			>
				{specials.map((special) =>
					<SwiperSlide
						key={special.id}
					>
						<div className="specials-carousel__item">
							<SpecialCard item={special} location={location} lazyLoad />
						</div>
					</SwiperSlide>)}
			</Swiper>
		</div>
	</ResizeObserverContainer>;
};

export default SpecialsCarousel;
