import React from 'react';
import { useCart } from 'react-use-cart';

import { getMoneyString } from '@commonTuna/react/components/Utils';
import ImageWithSkeleton from '@commonTuna/react/components/UI/ImageWithSkeleton/ImageWithSkeleton';

import '@app/scss/components/shoppingCartInformation.scss';
import TextWithTooltip from '@app/components/UI/TextWithTooltip/TextWithTooltip';
import AddToCartButton from '@app/components/UI/AddToCartButton/AddToCartButton';
import EmptyCart from '@app/components/UI/EmptyCart/EmptyCart';
import { CartItem, transformStoredItemToCartItem } from '@app/objects/CartItem';
import useShoppingCart from '@app/hooks/useShoppingCart';
import { SpecialLocation } from '@app/components/Pages/Specials/SpecialCard';
import WithPathLink from '@app/components/UI/WithPathLink/WithPathLink';
import { getLocationName } from '@app/components/Utils';

import noImage from '@images/no-image.jpg';

interface Props {
	checkout?: React.ReactNode;
}

const CartItemTitle: React.FC<{name: string}> = ({ name }) => {
	return <TextWithTooltip
		text={name}
		count={22}
		tooltipProps={{
			placement: 'topRight',
		}}
	/>;
};

const ShoppingCartItem: React.FC<{item: CartItem}> = ({ item }) => {
	const { removeCartItem } = useShoppingCart();

	return <div className="shopping-cart-information__item">
		<button type="button" className="cross" onClick={() => removeCartItem(item.id)} />
		<WithPathLink prefix="special" path={item.path}>
			<ImageWithSkeleton src={item.originalAvatar ? `/remote/${item.originalAvatar}` : noImage} alt={item.name} />
		</WithPathLink>
		<span className="main-info">
			<div className="main-info__left-side">
				<WithPathLink className="name" prefix="special" path={item.path}>
					<span className="main-info__title" title={item.name}>{item.name}</span>
				</WithPathLink>
				{item.location
					&& <div className="map-point">
						<i className="fa fa-map-marker" />
						<WithPathLink prefix="clinic" path={item.location.portalPathEn}>
							<SpecialLocation text={getLocationName(item.location as any, false, true) as string} />
						</WithPathLink>
					</div>
				}
				<span className="special-price mr10">{getMoneyString(item.price)}</span>
				<span className="special-original-price">{getMoneyString(item.originalPrice)}</span>
			</div>
			<div className="main-info__right-side">
				<AddToCartButton special={item} withTotal={false} />
				<div className="item-total">{getMoneyString(item.itemTotal ?? 0)}</div>
			</div>
		</span>
	</div>;
};

const ShoppingCartInformation: React.FC<Props> = ({ checkout }) => {
	const { items, cartTotal, isEmpty } = useCart();

	return <div className="shopping-cart-information">
		{isEmpty ? <EmptyCart />
			: <div className="shopping-cart-information__container">
				<ul className="cart-item-list">
					{items.map((item) =>
						<li key={item.id}>
							<ShoppingCartItem key={item.id} item={transformStoredItemToCartItem(item)} />
						</li>)}
				</ul>
				<div className="shopping-cart-information__bottom">
					<div className="total">
						Total:
						{' '}
						{getMoneyString(cartTotal)}
					</div>
				</div>
				{checkout}
			</div>
		}
	</div>;
};

export default ShoppingCartInformation;
