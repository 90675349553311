import React from 'react';
import { renderToString } from 'react-dom/server';
import { useHistory } from 'react-router-dom';

import Tabs, { TabsProps } from 'antd/lib/tabs';
import Modal from 'antd/lib/modal';
import GoogleMapReact from 'google-map-react';
import Tag from 'antd/lib/tag';

import LinkWithPrevLocation from '@common/react/components/UI/LinkWithPrevLocation/LinkWithPrevLocation';

import { numberWithComma } from '@commonTuna/react/utils/NumberWithComma/NumberWithComma';

import { LocationPortal } from '@app/objects/CompanyPortal';
import { Location } from '@app/objects/Location';
import WithPathLink from '@app/components/UI/WithPathLink/WithPathLink';
import { getLocationAddress, getLocationName, showTime } from '@app/components/Utils';
import Stars from '@app/components/UI/Stars/Stars';

import '@app/scss/components/locations.scss';

interface IPros {
	locations: Array<LocationPortal>;
	containerClassName?: string;
	content?: (location: LocationPortal) => JSX.Element;
	reviews?: (location: LocationPortal) => React.ReactNode;
	hideTitle?: boolean;
	hideLink?: boolean;
}

interface MapProps {
	address: string;
	addressList?: Array<string | Location>;
}

export const getAddress = (item) => {
	return [item.addressEn, item.city, item.state, item.zip].filter((e) => e).join(', ');
};

const getClinicAvatar = (location: Location): string => {
	if (location.portalOriginalAvatar) {
		return `/remote/${location.portalOriginalAvatar}`;
	}
	if (location.portalAvatar) {
		return `/remote/${location.portalAvatar}`;
	}
	return '';
};

function codeAddress(map, maps, address: string, addressList?: Array<string | Location>, onLoad?: (address, result, openPopup, callback) => void) {
	const geocoder = new maps.Geocoder();
	const gedData = (item: string | Location) => {
		const isLocation = typeof item !== 'string';
		const currentAddress = !isLocation ? item : getAddress(item);
		geocoder.geocode({ address: currentAddress }, (results, status) => {
			if (status === 'OK') {
				if (address === currentAddress) {
					map.setCenter(results[0].geometry.location);
				}
				// eslint-disable-next-line
				const marker = new maps.Marker({
					map,
					position: results[0].geometry.location,
					clickable: true,
				});
				if (isLocation) {
					const locationName = getLocationName(item, false, true);
					const avatar = getClinicAvatar(item);
					const infowindow = new google.maps.InfoWindow({
						content: `${isLocation ? renderToString(<div className="location-map-info">
							<h4>
								<a style={{ cursor: 'pointer' }} data-link={`/clinic/${item.portalPathEn}`}>{locationName}</a>
							</h4>
							{avatar ? <div className="location-map-info-avatar">
								<img
									src={avatar}
									alt={locationName as string}
								/>
							</div> : ''}
							<div className="doctor-rate">
								<strong className="stars">
									<Stars value={item.averageReviewRate || 0} />
								</strong>
								{' '}
								<span>
									{numberWithComma(item.averageReviewRate)}
									{' '}
									(
									{item.totalReviewCount}
									)
								</span>
							</div>
							<LocationNode
								hideMap
								hideLink
								content=""
								addressAsLink
								item={item}
							/>
							{item.portalDescription
								&& <div
									className="mt10"
									dangerouslySetInnerHTML={{ __html: item.portalDescription }}
								/>
							}
						</div>) : currentAddress}`,
						ariaLabel: '',
					});
					const onClick = () => {
						infowindow.open({
							anchor: marker,
							map,
						});
					};
					marker.addListener('click', onClick);
					if (address === currentAddress) {
						onClick();
					}
					onLoad && onLoad(currentAddress, results[0].geometry.location, {
						open: onClick,
						close: () => {
							infowindow.close();
						},
					}, () => {
						marker.removeListener('click', onClick);
					});
				} else {
					onLoad && onLoad(currentAddress, results[0].geometry.location, undefined, () => undefined);
				}
			} else {
				console.log(`Geocode was not successful for the following reason: ${status}`);
			}
		});
	};
	const list: any = addressList || [];
	(list?.map((item) => (typeof item === 'string' ? item : getAddress(item))).includes(address) ? list : address ? [address].concat(list) : list)
		.forEach(gedData);
}

export const SimpleMap: React.FC<MapProps> = ({ address, addressList }) => {
	const history = useHistory();
	const [maps, setMaps] = React.useState<any>();
	const [map, setMap] = React.useState<any>();
	const listenersRef = React.useRef<any>([]);
	const addressRef = React.useRef<any>({});
	const popupRef = React.useRef<any>({});
	const defaultProps = {
		center: { lat: -34.397, lng: 150.644 },
		zoom: 12,
	};

	React.useEffect(() => {
		if (map && maps) {
			if (addressRef.current[address]) {
				map.setCenter(addressRef.current[address]);
				if (popupRef.current[address]) {
					Object.values(popupRef.current).forEach((item: any) => {
						item?.close();
					});
					popupRef.current[address]?.open();
				}
			} else {
				codeAddress(map, maps, address);
			}
		}
	}, [address]);

	React.useEffect(() => {
		if (listenersRef.current.length) {
			return () => listenersRef.current.forEach((callback) => callback());
		}
	}, []);

	const renderMarker = (map, maps) => {
		setMap(map);
		setMaps(maps);
		return codeAddress(map, maps, address, addressList, (address, result, openPopup, callback) => {
			listenersRef.current.concat(callback);
			addressRef.current[address] = result;
			popupRef.current[address] = openPopup;
		});
	};

	return (
		<div className="location-map">
			<GoogleMapReact
				onClick={(e) => {
					const target: any = e.event.target;
					if (target && target.dataset.link) {
						e.event.preventDefault();
						history.push(target.dataset.link);
					}
				}}
				bootstrapURLKeys={{ key: 'AIzaSyB_Vis358nPu31ZjN8OUdxbMwr9H9JgSso' }}
				defaultCenter={defaultProps.center}
				defaultZoom={defaultProps.zoom}
				onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
			/>
		</div>
	);
};

interface ILocationModal {
	location: Location;
	buttonClassName?: string;
	buttonValue?: JSX.Element;
}

export const LocationModal: React.FC<ILocationModal> = ({ location, buttonValue, buttonClassName }) => {
	const [visible, setVisible] = React.useState<boolean>(false);

	const close = () => setVisible(false);

	const open = () => setVisible(true);
	const { addressEn, zip, city } = location;

	return (
		<>
			<button type="button" onClick={open} className={`btn ${buttonClassName || ''}`}>
				{buttonValue || location.nameEn}
			</button>
			<Modal
				open={visible}
				onCancel={close}
				title={<>
					<WithPathLink prefix="clinic" path={location.portalPathEn}>
						{location.nameEn}
					</WithPathLink>
				</>}
				width={600}
				className="location-popap"
				footer={null}
			>
				<div>
					<strong>Address:</strong>
					{' '}
					{addressEn}
					,
					{' '}
					{city}
					,
					{' '}
					{zip}
				</div>
				<div style={{ marginBottom: '10px' }}>
					<strong>Phone:</strong>
					{' '}
					{location.phone}
				</div>
				<SimpleMap address={`${addressEn}${city ? `, ${city}` : ''}${zip ? `, ${zip}` : ''}`} />
			</Modal>
		</>
	);
};

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const LocationNode: React.FC<{ content, reviews?, item, hideLink?, hideMap?, addressAsLink?: boolean}> = ({
	item, reviews, content, hideLink, hideMap = false, addressAsLink,
}) => {
	const address = React.useMemo(() => getLocationAddress(item), []);
	const link = React.useMemo(() => {
		let res = `https://maps.google.com/maps/search/?api=1&query=${address}`;
		if (typeof window !== 'undefined') {
			if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
				res = `maps://maps.google.com/maps/search/?api=1&query=${address}`;
			}
		}
		return res;
	}, [item]);

	const workingHours = React.useMemo(() => {
		if (!item.workingHours) return [];
		const whs = item.workingHours?.slice()
			?.sort((a, b) => {
				if (a.dayOfWeek === 0) return 1;
				if (b.dayOfWeek === 0) return -1;
				return a.dayOfWeek - b.dayOfWeek;
			});
		const obj: any = {};
		whs.forEach((wh) => {
			const key = `${wh.startTime}-${wh.endTime}`;
			if (!obj[key]) {
				obj[key] = [{ ...wh }];
			} else {
				obj[key] = obj[key].concat(wh);
			}
		});
		return Object.values(obj)
			.map((arr: any, i) => {
				let title = days[arr[0].dayOfWeek];
				for (let i = 1; i < arr.length; i++) {
					const isEnd = arr[i].dayOfWeek === 6 && arr[i + 1]?.dayOfWeek === 0;
					if (arr[i].dayOfWeek - 1 !== arr[i - 1]?.dayOfWeek && !isEnd && !(arr[i].dayOfWeek === 0 && arr[i - 1]?.dayOfWeek === 6)) {
						title = `${title}, ${days[arr[i].dayOfWeek]}`;
					} else if (i + 1 === arr.length
						|| (arr[i].dayOfWeek + 1 !== arr[i + 1]?.dayOfWeek && !isEnd)) {
						title = `${title} - ${days[arr[i].dayOfWeek]}`;
					}
				}
				return {
					id: i,
					title,
					startTime: arr[0].startTime,
					endTime: arr[0].endTime,
				};
			});
	}, [item.workingHours]);

	return <div className="row card-panel location">
		<div className="clearfix">
			{address ? <>
				<strong>
					Address:&nbsp;
				</strong>
				{addressAsLink ? <a style={{ cursor: 'pointer' }} href={link}>{address}</a> : address}
			</> : null}
			{workingHours.length ? <>
				<br />
				<strong>
					Working Hours:&nbsp;
				</strong>
				{workingHours.map((wh) => <React.Fragment
					key={wh.id}
				>
					<Tag color="orange">
						<strong>
							{wh.title}
							:
						</strong>
						{' '}
						{showTime(wh.startTime)}
						{' '}
						-
						{' '}
						{showTime(wh.endTime)}
					</Tag>
				</React.Fragment>)}
			</> : null}
			{hideLink ? null : <div className="pull-right">
				<LinkWithPrevLocation
					className="pull-right"
					to={{
						pathname: `/clinic/${item.portalPathEn}`,
					}}
				>
					More About
					{' '}
					{item.nameEn}
					{' '}
					Clinic
				</LinkWithPrevLocation>
			</div>}
		</div>
		{hideMap ? null : <div className="location-inner">
			<SimpleMap address={item.addressEn} />
			<div className="info-block">
				{content && content(item)}
			</div>
		</div>}
		{reviews && <div className="location-reviews">
			{reviews && reviews(item)}
		</div>
		}
	</div>;
};

const Locations: React.FC<IPros> = ({
	locations, containerClassName, content, reviews, hideTitle, hideLink,
}) => {
	if (locations.length === 1) {
		const item = locations[0];
		return (
			<div className={`mb20 locations scroll-top ${containerClassName || ''}`}>
				<div className="location-tab">
					{hideTitle ? null : <h4>
						<div className="stars">
							<strong className="mr10">
								<Stars value={item.averageReviewRate < 1 ? 1 : item.averageReviewRate} />
							</strong>
							{numberWithComma(item.averageReviewRate)}
							{' '}
							(
							{item.totalReviewCount || 0}
							)
						</div>
						{getLocationName(item, false)}
					</h4>}
					<LocationNode
						content={content}
						item={item}
						reviews={reviews}
						hideLink={hideLink}
					/>
				</div>
			</div>
		);
	}

	const items: TabsProps['items'] = locations.map((item, index) => {
		return {
			key: index.toString(),
			label: getLocationName(item, false),
			className: 'location-tab',
			children: <LocationNode
				content={content}
				item={item}
				reviews={reviews}
			/>,
		};
	});

	return (
		locations.length > 0 ? <div className={`mb20 locations scroll-top ${containerClassName || ''}`}>
			<Tabs type="card" defaultActiveKey="0" items={items} />
		</div> : null
	);
};

export default Locations;
