import { Item } from 'react-use-cart';

import { Nullable } from '@common/typescript/objects/Nullable';

import { Special } from '@app/objects/Special';

export interface CartItem extends Item {
	name: string;
	originalPrice: number;
	description: string;
	path: string;
	locationId: Nullable<number>;
	location: {
		id: number;
		nameEn: string,
		nameEs: string,
		portalPathEn: string;
		company?: {
			portalShortName: string;
			name: string;
		}
	};
	avatar: string;
	originalAvatar: string;
}

export const transformSpecialToCartItem = (special: Special | CartItem): CartItem => {
	return {
		id: special.id.toString(),
		price: special.price,
		name: special.name,
		originalPrice: special.originalPrice,
		path: special.path,
		description: special.description,
		locationId: special.locationId,
		location: {
			id: special.location?.id ?? 0,
			nameEn: special.location?.nameEn ?? '',
			nameEs: special.location?.nameEs ?? '',
			portalPathEn: special.location?.portalPathEn ?? '',
			company: {
				portalShortName: special.location?.company?.portalShortName ?? '',
				name: special.location?.company?.name ?? '',
			},
		},
		avatar: special.avatar,
		originalAvatar: special.originalAvatar,
	};
};

export const transformStoredItemToCartItem = (item: Item): CartItem => {
	return {
		id: item.id,
		name: item.name || '',
		price: item.price || 0,
		originalPrice: item.originalPrice || 0,
		path: item.path || '',
		description: item.description || '',
		locationId: item.locationId || null,
		location: item.location || { id: 0, nameEn: '', nameEs: '' },
		avatar: item.avatar || '',
		originalAvatar: item.originalAvatar || '',
		quantity: item.quantity,
		itemTotal: item.itemTotal,
	};
};
