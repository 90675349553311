import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface BreadcrumbLink {
	title: string;
	path: string;
}

interface Props {
	title?: string;
	links?: Array<BreadcrumbLink>;
}

export const SimpleBreadCrumbs: React.FC<Props> = ({
	title,
	links = undefined,
}) => {
	const { t } = useTranslation();

	const innerLinks = [{ title: 'Home', path: '/' }].concat(links || []);

	return <ol className="breadcrumb">
		{/* eslint-disable-next-line react/no-array-index-key */}
		{innerLinks.map((item, index) => <li key={index}>
			<NavLink to={t(item.path)}>{t(item.title)}</NavLink>
		</li>)}
		{title && <li className="active">{title}</li>}
	</ol>;
};
